import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import PendingHelloSignForms from '../CandidatesList/QuickProfileCandidate/PendingHellosignForms';
import FileViewer from './FileViewer';

import { DocumentIcon, DownloadIconGrey, UploadIconGrey } from 'ui-kit/icons';
import { ReactComponent as VideoIcon } from 'assets/icon-video.svg';

import { questionType } from 'utils/enums';
import auth from 'utils/auth';
import { QuickProfileUser } from 'types/types';
import { useHistory } from 'react-router-dom';
import { BoxContainer } from '../CandidatesList/QuickProfileCandidate/QuickView.styles';

interface Props {
  fullUser: QuickProfileUser;
  districtApplicationBeingQuickviewed;
  openDocumentUploadModal?: (districtApplicationId: number) => void;
  openHellosignTemplateModal?: () => void;
  helloSignTemplateModalOpen?: boolean;
  showDownloadIcon: boolean;
  isTalentMarketplace?: boolean;
  isCandidateList?: boolean;
}

interface AttachmentsHeaderContainerProps {
  countIconsShowing: number;
}

export default function Attachments({
  fullUser,
  districtApplicationBeingQuickviewed,
  openDocumentUploadModal,
  openHellosignTemplateModal,
  helloSignTemplateModalOpen,
  showDownloadIcon,
  isCandidateList,
  isTalentMarketplace,
}: Props): JSX.Element {
  const history = useHistory();
  let attachmentAnswers = [];
  let videoLinkAnswers = [];

  // Combine & sort attachment & video link questions
  if (districtApplicationBeingQuickviewed) {
    attachmentAnswers = districtApplicationBeingQuickviewed.applicationattachment_set || [];

    attachmentAnswers = attachmentAnswers.map((answer) => {
      let question;
      if (answer.required_application_attachment) {
        question = districtApplicationBeingQuickviewed.role.requiredapplicationattachment_set.find(
          (question) => answer.required_application_attachment === question.id
        );
      }

      // Addtional documents don't have an associated question and therefore don't have order
      if (!question) {
        question = { order: -1 };
      }

      return { ...answer, question };
    });

    videoLinkAnswers = districtApplicationBeingQuickviewed.applicationattachmentvideourl_set || [];
    videoLinkAnswers = videoLinkAnswers.map((answer) => {
      const question = answer.required_application_attachment;
      return { ...answer, question };
    });
  }

  const attachmentAndVideoLinkAnswers = [...attachmentAnswers, ...videoLinkAnswers].sort(
    (a, b) => a.question.order - b.question.order
  );

  let countIconsShowing = 0;
  let showFormSendIcon = false;
  let renderDownloadIcon = false;
  let renderUploadIcon = false;
  if (auth.isDistrictAdmin()) {
    showFormSendIcon = true;
    countIconsShowing += 1;
  }
  if (showDownloadIcon) {
    renderDownloadIcon = true;
    countIconsShowing += 1;
  }
  if (auth.isDistrictUser()) {
    renderUploadIcon = true;
    countIconsShowing += 1;
  }

  return (
    <>
      {isCandidateList && (
        <BoxContainer>
          <AttachmentsHeaderContainer countIconsShowing={countIconsShowing}>
            <HeaderLabel>Attachments</HeaderLabel>
            {auth.hasOnboardingModule() && showFormSendIcon && (
              <DocumentIcon
                className="pointer"
                data-tip
                data-testid="send-form"
                data-for="form"
                data-place="bottom"
                data-delay-show="100"
                onClick={openHellosignTemplateModal}
              />
            )}
            <ReactTooltip id="form" effect="solid">
              <span style={{ color: 'white' }}>Send a form</span>
            </ReactTooltip>
            {renderDownloadIcon && (
              <DownloadIconGrey
                className="pointer"
                onClick={() =>
                  window.open(
                    `/api/attachments/${districtApplicationBeingQuickviewed.id}?uid=${fullUser.id}`,
                    '_blank'
                  )
                }
                data-tip
                data-for="download"
                data-place="bottom"
                data-delay-show="100"
              />
            )}
            <ReactTooltip id="download" effect="solid">
              <span style={{ color: 'white' }}>Download files</span>
            </ReactTooltip>
            {renderUploadIcon && (
              <UploadIconGrey
                className="pointer"
                data-tip
                data-for="upload"
                data-place="bottom"
                data-delay-show="100"
                onClick={() => openDocumentUploadModal(districtApplicationBeingQuickviewed.id)}
              />
            )}
            <ReactTooltip id="upload" effect="solid">
              <span style={{ color: 'white' }}>Upload file</span>
            </ReactTooltip>
          </AttachmentsHeaderContainer>

          {fullUser.profile.resume && (
            <FileViewer file={fullUser.profile.resume} title={'Resume'} />
          )}
          {districtApplicationBeingQuickviewed &&
            attachmentAndVideoLinkAnswers.map((answer, index) => {
              if (answer.question && answer.question.question_type === questionType.videoLink) {
                return (
                  <FileViewer
                    key={index}
                    file={answer.video_url}
                    title={answer.question.title}
                    icon={<VideoIcon />}
                  />
                );
              } else if (
                answer.question &&
                answer.question.question_type === questionType.attachment
              ) {
                return (
                  <FileViewer
                    key={index}
                    file={answer.attachment}
                    title={answer.question && answer.question.title}
                  />
                );
              } else {
                // Additional uploaded documents
                return <FileViewer key={index} file={answer.attachment} title={answer.title} />;
              }
            })}
          {fullUser.attachments &&
            fullUser.attachments.map((file) =>
              file.attachment ? (
                <FileViewer key={file.id} file={file.attachment} title={file.title} />
              ) : (
                ''
              )
            )}
          {districtApplicationBeingQuickviewed?.id && (
            <>
              <PendingHelloSignForms
                helloSignTemplateModalOpen={helloSignTemplateModalOpen}
                application={districtApplicationBeingQuickviewed}
              />
            </>
          )}
        </BoxContainer>
      )}
      {isTalentMarketplace && (
        <MarketplaceCredentialContainer>
          <MarketplaceAttachmentsHeaderContainer>
            <MarketplaceHeaderLabel>Attachments</MarketplaceHeaderLabel>
            {renderDownloadIcon && (
              <DownloadIconContainer>
                <DownloadIconGrey
                  className="pointer"
                  onClick={() =>
                    history.push(
                      `/api/attachments/${districtApplicationBeingQuickviewed.id}?uid=${fullUser.id}`,
                      '_blank'
                    )
                  }
                  data-tip
                  data-for="download"
                  data-place="bottom"
                  data-delay-show="100"
                />
              </DownloadIconContainer>
            )}
            <ReactTooltip id="download" effect="solid">
              <span style={{ color: 'white' }}>Download files</span>
            </ReactTooltip>
          </MarketplaceAttachmentsHeaderContainer>
          {fullUser.profile.resume && (
            <FileViewerContainer>
              <FileViewer file={fullUser.profile.resume} title={'Resume'} />
            </FileViewerContainer>
          )}
          {fullUser.attachments &&
            fullUser.attachments.map((file) =>
              file.attachment ? (
                <FileViewerContainer key={file.id}>
                  <FileViewer key={file.id} file={file.attachment} title={file.title} />
                </FileViewerContainer>
              ) : (
                ''
              )
            )}
        </MarketplaceCredentialContainer>
      )}
    </>
  );
}

const AttachmentsHeaderContainer = styled.div<AttachmentsHeaderContainerProps>`
  display: grid;
  grid-template-columns: 1fr repeat(${({ countIconsShowing }) => countIconsShowing}, 20px);
  grid-gap: 20px;
  align-items: center;

  margin-bottom: 1rem;
`;

export const MarketplaceAttachmentsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DownloadIconContainer = styled.div`
  display: flex;
  align-self: end;
  justify-self: end;
  justify-content: end;
  justify-items: end;
  width: 100%;
  padding-right: 35px;
  margin-bottom: 16px;
`;

export const UploadIconContainer = styled.div`
  display: flex;
  align-self: end;
  justify-self: end;
  justify-content: end;
  justify-items: end;
  width: 100%;
  margin-bottom: 16px;
  padding-right: 35px;
`;

export const FileViewerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 35px;
  margin-bottom: 1rem;
`;

export const MarketplaceHeaderLabel = styled.div`
  color: #4F4F4F;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  line-height: 24px;
  display; flex;
  align-items: center;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`;

export const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const MarketplaceCredentialContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-top: 16px;
  padding-left: 19px;
`;
