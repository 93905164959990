import { useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import useSWR from 'swr';

import {
  BoldRoleText,
  BoldText,
  CandidateName,
  Container,
  Detail,
  Header,
  HeaderBottom,
  HeaderTop,
  Recommendation,
  RecommendBox,
} from './styles';
import { finalRecommendation } from 'utils/enums';
import { getQueryStringValue } from 'utils/util';
import { PrintDivider } from './ScorecardPrintView';
import { ReviewerQuestionsAndAnswers } from './ReviewerQuestionsAndAnswers';
import { Typography, useTheme } from '@mui/material';
import ApplicationsAPI from 'api/applicationsAPI';
import LoadingSpinner from 'components/loadingSpinner';
import referenceCheckAPI from 'api/referenceCheckAPI';

export const ReferencePrintView: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();

  const splitPathname = location.pathname.split('/');
  const referenceId = splitPathname[4];
  const applicationId = getQueryStringValue('application');
  const candidateId = splitPathname[2];

  const REFERENCE_KEY = `/api/referencechecks/${referenceId}`;
  const { data: referenceData } = useSWR(REFERENCE_KEY, () => referenceCheckAPI.fetch(referenceId));

  const APPLICATION_KEY = `/api/application/${applicationId}`;
  const { data: applicationData } = useSWR(APPLICATION_KEY, () =>
    ApplicationsAPI.getApplication(applicationId)
  );

  const CANDIDATE_KEY = `/api/full-profile/user/${candidateId}/?a_pk=${applicationId}`;
  const fetcher = (url) => axios.get(url).then((response) => response.data);
  const { data: candidateData } = useSWR(CANDIDATE_KEY, fetcher);

  const finalRecommendationToUse = finalRecommendation.find(
    (recommendation) => recommendation.value === referenceData?.final_recommendation
  );

  const shouldShowCumulativeScore =
    referenceData?.include_cumulative_score && referenceData?.cumulative_score;
  const shouldShowRecommendationBox = finalRecommendationToUse || shouldShowCumulativeScore;

  const dataIsLoading =
    referenceData === undefined || applicationData === undefined || candidateData === undefined;

  const currentReference = candidateData?.district_references.filter(
    (reference) => reference.name === referenceData?.submitted_by_name
  )[0];

  useEffect(() => {
    if (!dataIsLoading) {
      setTimeout(window.print, 100);
    }
  }, [dataIsLoading]);

  if (dataIsLoading) {
    return <LoadingSpinner />;
  }

  const getTitleAndOrganization = () => {
    if (!currentReference?.title && !currentReference?.organization) return;

    if (currentReference?.title && !currentReference?.organization) {
      return currentReference.title;
    } else if (!currentReference?.title && currentReference?.organization) {
      return currentReference.organization;
    } else {
      return `${currentReference.title}, ${currentReference.organization}`;
    }
  };

  const shouldShowTitleAndOrganization = currentReference?.title || currentReference?.organization;

  return (
    <Container>
      <Header>
        <HeaderTop>
          <CandidateName variant="body1">{candidateData?.name}</CandidateName>
          {shouldShowRecommendationBox && (
            <Recommendation>
              {finalRecommendationToUse?.label && (
                <RecommendBox sx={{ paddingRight: theme.spacing(2) }}>
                  Recommend?: <BoldText>{finalRecommendationToUse?.label}</BoldText>
                </RecommendBox>
              )}
              {shouldShowCumulativeScore && (
                <RecommendBox>
                  Cumulative score:
                  <BoldText>
                    {referenceData?.cumulative_score}/{referenceData?.max_cumulative_score}
                  </BoldText>
                </RecommendBox>
              )}
            </Recommendation>
          )}
        </HeaderTop>

        <HeaderBottom>
          <Detail>
            <BoldRoleText>Role:</BoldRoleText> &nbsp;{' '}
            <Typography sx={{ fontSize: theme.typography.body3.fontSize }}>
              {applicationData?.role.title}
            </Typography>
          </Detail>
        </HeaderBottom>
        <HeaderBottom sx={{ paddingTop: theme.spacing(2) }}>
          <BoldText>Reference Information</BoldText>
          <Detail>{currentReference?.name}</Detail>
          {shouldShowTitleAndOrganization && <Detail>{getTitleAndOrganization()}</Detail>}
          {currentReference?.email && <Detail>{currentReference.email}</Detail>}
          {currentReference?.phone && <Detail>{currentReference.phone}</Detail>}
          <Detail>Completed on {moment(referenceData?.created).format('L')}</Detail>
        </HeaderBottom>
      </Header>
      <PrintDivider />
      {referenceData && <ReviewerQuestionsAndAnswers data={referenceData} />}
    </Container>
  );
};
