import { useEffect, useState } from 'react';

import { assignTag, removeTag, validateTagUserType } from 'components/CustomTag/util';
import { CloseButton, Section } from '../TalentQuickView';
import { FullUser } from '../QuickProfile/types';
import { AdminUser, InternalCandidateNote, MarketplaceProspectQuickViewProps } from './types';
import { uploadFileToUser } from 'utils/fileUpload';
import ActivityFeed from './ActivityFeed';
import auth from 'utils/auth';
import axios from 'axios';
import Certification from 'components/Profile/Basics/Certification';
import CloseIcon from '../../../assets/icon-close-x.svg';
import customTagAPI from 'api/customTagAPI';
import CustomTagSection from 'components/CustomTag/CustomTagProfile/CustomTagSection';
import EmailModal from 'components/EmailModal';
import InternalNotes from './InternalNotes';
import LowerProfileDetails from '../QuickProfile/LowerProfileDetails';
import ProfileHeader from '../QuickProfile/ProfileHeader';
import ProspectAttachments from './Attachments';
import prospectDetailAPI from 'api/prospectDetailAPI';
import UploadProspectDocumentModal from 'components/Prospects/UploadProspectDocumentModal';
import useClickOutside from 'hooks/useClickOutside';
import QuickProfileApplicationSection from 'components/Profile/ProfileApplicationSection/QuickProfileApplicationSection';
import Overview from './Overview';

import eventsAPI from 'api/eventsAPI';
import usersAPI from 'api/usersAPI';
import { getATSCredentials } from 'utils/candidateUtils';

const MarketplaceProspectQuickView: React.FC<MarketplaceProspectQuickViewProps> = ({
  emailTemplatesList,
  partialProspect,
  show,
  closeQuickview,
  sources,
  updateProspectStatus,
  adminUsers,
  onClose,
}) => {
  const [fullUser, setFullUser] = useState<FullUser>({ ...partialProspect });
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const isDistrictAdmin = auth.isDistrictAdmin();
  const [updated, setUpdated] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [districtTags, setDistrictTags] = useState([]);
  const [events, setEvents] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipientList, setRecipientList] = useState([fullUser]);
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);

  useEffect(() => {
    fetchProspect();
    fetchEvents();
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fullUser.modified) {
      setUpdated(fullUser.modified);
      setUpdatedBy(fullUser.modifiedBy);
    }
  }, [fullUser]);

  useEffect(() => {
    document.body.classList.add('quick-profile-open');

    return () => {
      document.body.classList.remove('quick-profile-open');
    };
  }, []);

  const fetchTags = () => {
    customTagAPI.fetchCustomTags().then((tags) => setDistrictTags(tags));
  };

  const fetchProspect = () => {
    prospectDetailAPI.getProspect(partialProspect.prospect_id).then((prospect) => {
      setFullUser(prospect);
    });
  };

  const fetchEvents = async () => {
    const response = await eventsAPI.getEvents(partialProspect.id);
    setEvents(response);
  };

  const handleSetEmailModalOpen = () => {
    setEmailModalOpen(true);
  };

  const handleUpdateInternalCandidateNotes = async (
    newNote: InternalCandidateNote,
    recipientList: AdminUser[],
    message: string
  ) => {
    const newFullUser = { ...fullUser };
    newFullUser.internal_candidate_notes.unshift(newNote);
    setFullUser(newFullUser);

    await usersAPI.updateUser(partialProspect.id, {
      internalCandidateNote: newNote,
    });

    emailTaggedUsers(recipientList, message, 'note');
  };

  const handleActionDropdownClick = () => setShowActionDropdown((previousState) => !previousState);

  const updateFullUser = (fieldName, value) => {
    const newFullUser = { ...fullUser };
    newFullUser[fieldName] = value;
    setFullUser(newFullUser);
  };

  const addTag = async (tagId) => {
    await assignTag(tagId, fullUser);
    fetchProspect();
  };

  const deleteTag = async (tagId) => {
    await removeTag(tagId, fullUser);
    fetchProspect();
  };

  const buildUrl = () => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;

    return `${location}/prospectlist?query=${partialProspect.name.replace(' ', '+')}`;
  };

  const sendTagNotifications = (tagObjects) => {
    return axios.post('/api/send_tag_notifications/', tagObjects).catch((err) => console.log(err));
  };

  const emailTaggedUsers = (recipientList: AdminUser[], message: string, type: 'note') => {
    const tagObjects = [];

    recipientList.forEach((recipient) => {
      const url = buildUrl();
      const tagObj = {
        user_id: recipient.id,
        location: `in a ${type}`,
        note_url: url,
        note_copy: message,
        candidate_name: partialProspect.name,
      };
      tagObjects.push(tagObj);
    });

    sendTagNotifications(tagObjects);
  };

  const updateEmailTemplateSelection = (event) => {
    const emailTemplateProp = emailTemplatesList.find(
      (template) => template.id === Number(event.target.value)
    );

    if (emailTemplateProp) {
      const emailTemplate = Object.assign({}, emailTemplateProp);

      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }

      setEmailTemplate(emailTemplate);
      setSubject(emailTemplate.subject);
      setMessage(emailTemplate.content);

      if (event.target.value === null) {
        setEmailTemplate({});
        setSubject('');
        setMessage('');
      }
    }
  };
  const shouldShowTags =
    districtTags.length > 0 && districtTags.some((tag) => validateTagUserType(auth.getUser(), tag));

  const onHide = () => {
    setEmailTemplate({});
    setSubject('');
    setMessage('');
    setEmailModalOpen(false);
    setRecipientList([partialProspect]);
  };

  const onHideProspectModal = () => {
    setUploadDocumentModalOpen(false);
  };

  const submitUserAttachment = async (file, fileName) => {
    return uploadFileToUser(partialProspect.id, file, fileName);
  };

  const postSubmitDocuments = () => {
    fetchProspect();
    fetchEvents();
  };

  const openDocumentUploadModal = () => {
    setUploadDocumentModalOpen(true);
  };

  const additionalUpdateProspectStatus = (selectedStatus, prospect_id) => {
    const updateObject = { status: selectedStatus.value };
    updateProspectStatus(selectedStatus, prospect_id);
    prospectDetailAPI.updateProspectPartial(prospect_id, updateObject).then(fetchEvents); // double check this
  };

  const quickProfileRef = useClickOutside<HTMLDivElement>(() => {
    if (uploadDocumentModalOpen || emailModalOpen) return;
    onClose();
  });

  const showActionDropdownRef = useClickOutside<HTMLImageElement>(() => {
    if (!showActionDropdown) return;

    setShowActionDropdown(true);
  });

  const atsCredentials = getATSCredentials(fullUser.credentials);

  return (
    <>
      <div className="quick-profile-fade" hidden={!show} />
      {show && (
        <div className="quick-profile-container" ref={quickProfileRef}>
          <>
            <div className="flex-1 quick-profile-inside">
              <CloseButton onClick={closeQuickview}>
                <img alt="icon-clock" src={CloseIcon} style={{ paddingRight: '6px' }} />
              </CloseButton>
            </div>
            <div className="default-card">
              <div className="profile-section one">
                <ProfileHeader
                  handleSetEmailModalOpen={handleSetEmailModalOpen}
                  handleActionDropdownClick={handleActionDropdownClick}
                  name={fullUser.name}
                  showActionDropdown={showActionDropdown}
                  showActionDropdownRef={showActionDropdownRef}
                />
                <LowerProfileDetails
                  createdAt={fullUser.created_at}
                  fetchProspect={fetchProspect}
                  fullUser={fullUser}
                  hasTalentMarketplaceSource={true}
                  isDistrictAdmin={isDistrictAdmin}
                  partialUser={fullUser}
                  sources={sources}
                  updated={updated}
                  updatedBy={updatedBy}
                  updateFullUser={updateFullUser}
                  updateProspectStatus={additionalUpdateProspectStatus}
                />
                {emailModalOpen && (
                  <EmailModal
                    candidate={fullUser}
                    emailTemplate={emailTemplate}
                    emailTemplatesList={emailTemplatesList}
                    footer={null}
                    message={message}
                    onHide={onHide}
                    onSend={onHide}
                    recipientList={recipientList}
                    show={emailModalOpen}
                    showTemplates={true}
                    subject={subject}
                    templateEmailModal={false}
                    updateEmailTemplateSelection={updateEmailTemplateSelection}
                  />
                )}
                {uploadDocumentModalOpen && (
                  <UploadProspectDocumentModal
                    onHide={onHideProspectModal}
                    postSubmitCallback={postSubmitDocuments}
                    show={uploadDocumentModalOpen}
                    submitAdditionalDocument={submitUserAttachment}
                  />
                )}
              </div>
              {fullUser.years_experience && (
                <Section>
                  <Overview yearsExperience={fullUser.years_experience} />
                </Section>
              )}

              <Section>
                <ProspectAttachments
                  attachments={fullUser.attachments}
                  userId={fullUser.id}
                  openDocumentUploadModal={openDocumentUploadModal}
                  resume={fullUser.resume}
                />
              </Section>

              <Section>
                <Certification
                  hasNoCredentials={atsCredentials.length === 0}
                  isTalentMarketplace
                  subjects={atsCredentials}
                />
              </Section>

              <Section>
                <QuickProfileApplicationSection
                  user={fullUser}
                  districtApplication={null}
                  isTalentMarketplace
                />
              </Section>

              {shouldShowTags && (
                <div className="profile-section">
                  <CustomTagSection
                    assignTag={addTag}
                    districtTags={districtTags}
                    fullUser={fullUser}
                    isFullProfile={false}
                    removeTag={deleteTag}
                    user={auth.getUser()}
                  />
                </div>
              )}

              <ActivityFeed events={events} fullUser={fullUser} />

              <InternalNotes
                adminUsers={adminUsers}
                handleUpdateInternalCandidateNotes={handleUpdateInternalCandidateNotes}
                notes={fullUser.internal_candidate_notes}
              />
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default MarketplaceProspectQuickView;
