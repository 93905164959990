import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DeletionModal } from 'sharedComponents/Modal/DeletionModal';

import CandidateTasksRow from './CandidateTasksRow';
import CandidateSignerTasks from 'features/HellosignEmbeddedSigning/CandidateSignerTasks';

export default function CandidateTasks({ user, updateCandidateTask }) {
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  const handleTaskUpdate = (task, action) => {
    if (action === 'delete') {
      setTaskToDelete(task);
      setShowDeletionConfirmationModal(true);
    } else {
      updateCandidateTask(task, action);
    }
  };

  const handleDeleteTask = () => {
    updateCandidateTask(taskToDelete, 'delete');
    setShowDeletionConfirmationModal(false);
  };

  const handleCloseDeletionModal = () => {
    setShowDeletionConfirmationModal(false);
    setTaskToDelete(null);
  };

  return (
    <div className="candidate-tasks-container">
      <HeaderLabel>Tasks</HeaderLabel>
      {user.tasks.map(task => (
        <CandidateTasksRow
          key={task.id}
          task={task}
          user={user}
          updateCandidateTask={handleTaskUpdate}
        />
      ))}
      <CandidateSignerTasks candidate={user} />
      {showDeletionConfirmationModal && (
        <DeletionModal
          onClose={handleCloseDeletionModal}
          onDelete={handleDeleteTask}
          open={showDeletionConfirmationModal}
          text="This task will get deleted permanently and will not be recoverable."
          title="Are you sure?"
        />
      )}
    </div>
  );
}

CandidateTasks.propTypes = {
  user: PropTypes.object.isRequired,
  updateCandidateTask: PropTypes.func.isRequired,
};

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;
