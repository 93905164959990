import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { VacanciesSectionProps } from '../types';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { styled } from '@mui/material';

import { JobDetailsHighlightRow } from '../HighlightRows/HighlightRowJobDetails';
import { DistrictWideBadge } from '../InfoBadges/DistrictWideBadge';
import { CONNECT_ACTION_LABEL } from 'features/MarketplaceV2/features/JobDiscovery/constants';

export const VacancyRow: React.FC<VacanciesSectionProps> = ({
  vacancy,
  isConnectModalView = false,
}) => {
  return (
    <VacancyRowStack isConnectModalView={isConnectModalView} direction={'row'}>
      <Stack direction={'column'}>
        <div style={{ display: 'flex' }}>
          <VacancyTitle>{vacancy.title}</VacancyTitle>
          {vacancy?.pooled_posting_type === 'pooled_posting' && <DistrictWideBadge />}
        </div>
        <VacancySubtitle>{vacancy?.subcategories?.join(', ')}</VacancySubtitle>
        {isConnectModalView && <JobDetailsHighlightRow vacancy={vacancy} />}
      </Stack>
    </VacancyRowStack>
  );
};

export const NoVacanciesSection: React.FC<VacanciesSectionProps> = () => {
  return (
    <NoVacanciesContainer>
      <VacancyTitle>No current job openings</VacancyTitle>
      <VacancySubtitle>
        {`${CONNECT_ACTION_LABEL} for this school to be notified when openings become available`}
      </VacancySubtitle>
    </NoVacanciesContainer>
  );
};

const VacancyRowStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isConnectModalView',
})(({ isConnectModalView }: { isConnectModalView?: boolean }) => ({
  margin: isConnectModalView ? '24px 0 0 0' : 'none',
  justifyContent: 'space-between',
  alignContent: 'center',
  textAlign: 'left',
  border: 'none',
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  padding: '16px',
  backgroundColor: isConnectModalView ? 'white' : 'initial',
}));

const NoVacanciesContainer = styled(Box)(() => ({
  background: '#FFF',
  border: '1px solid #EAECF0',
  borderRadius: '8px',
  margin: '4px 0px',
  padding: '16px 14px',
  width: '100%',
}));

const VacancyTitle = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 500,
  fontSize: '14px',
  color: '#344054',
}));

const VacancySubtitle = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 400,
  fontSize: '14px',
  color: '#475467',
}));
