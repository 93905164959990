import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

import { MoreInfoSliderProps } from '../types';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { Badge } from '../../../../sharedComponents/Badge/Badge';

export const SchoolDetailsSection: React.FC<MoreInfoSliderProps> = ({ school }) => {
  const schoolAddressString = (schoolAddressObject) => {
    const streetAddress = schoolAddressObject.line_1;
    const city = schoolAddressObject.city;
    const state = schoolAddressObject.state;
    const zip = schoolAddressObject.zipcode;
    return `${streetAddress}. ${city}, ${state} ${zip}`;
  };
  return (
    <MoreInfoSchoolDetailsContainer>
      {school && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Label>Location</Label>
          </Grid>
          <Grid item xs={12}>
            <Address>{schoolAddressString(school.physical_address)}</Address>
          </Grid>
          {school.district.has_four_day_work_week && (
            <>
              <Grid item xs={12}>
                <Label>Details</Label>
              </Grid>
              <Grid item>
                <Badge>4-day work week</Badge>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </MoreInfoSchoolDetailsContainer>
  );
};

const MoreInfoSchoolDetailsContainer = styled(Box)(() => ({
  width: '100%',
  border: 'solid 1px #EAECF0',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: '#F9FAFB',
  margin: '24px 0px',
}));

const Label = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 500,
  fontSize: '14px',
  color: '#101828',
}));

const Address = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 500,
  fontSize: '16px',
  color: '#101828',
}));
