import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, SxProps } from '@mui/material';

export interface DeleteButtonProps {
  onClick: () => void;
  sx?: SxProps;
  dataTestId: string;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, sx, dataTestId }) => {
  return (
    <IconButton onClick={onClick} sx={sx} data-testid={dataTestId}>
      <DeleteIcon />
    </IconButton>
  );
};
