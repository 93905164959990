import { Box, styled } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { nimbleTheme } from 'theme';
import { BulletPoint } from 'ui-kit/icons';
import { ExistingNotesProps, NoteRowProps } from './types';

export const ExistingNotes: React.FC<ExistingNotesProps> = ({ extended, notes }) => {
  notes.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0));

  return (
    <NotesAnimatedContainer extended={extended}>
      {notes.map((note) => (
        <NoteRow {...note} key={`${note.id}_${note.created_at}`} />
      ))}
    </NotesAnimatedContainer>
  );
};

const NoteRow: React.FC<NoteRowProps> = ({ text, created_at, created_by }) => {
  const [, setShouldShowDelete] = useState<boolean>(false);

  const signatureContent = `${created_by} - ${moment(created_at).format('MM/DD/YYYY [at] h:mm a')}`;

  return (
    <LineItem
      onMouseEnter={() => setShouldShowDelete(true)}
      onMouseLeave={() => setShouldShowDelete(false)}
    >
      <Box width="10px" height="full">
        <BulletPoint />
      </Box>
      <Box>
        <NoteTextContainer>{text}</NoteTextContainer>
        <Signature>{signatureContent}</Signature>
      </Box>
    </LineItem>
  );
};

const NotesAnimatedContainer = styled(Box)<{ extended: boolean }>(({ extended }) => ({
  maxHeight: extended ? '500px' : '140px',
  overflow: 'auto',
  marginBottom: '10px',
  WebkitTransition: 'max-height 1s ease',
}));

const LineItem = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'baseline',
  marginBottom: '10px',
});

const NoteTextContainer = styled(Box)({
  lineHeight: nimbleTheme.spacing(3),
});

const Signature = styled(Box)({
  fontSize: nimbleTheme.spacing(1.5),
  color: 'var(--darkgray)',
});
