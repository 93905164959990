import styled from 'styled-components';

import { finalRecommendation } from 'utils/enums';
import { FinalRecommendationAnswerProps } from './types';

const FinalRecommendationAnswer: React.FC<FinalRecommendationAnswerProps> = ({
  answer,
  parentObject,
  updateFinalRecommendation,
}) => {
  const options = parentObject.include_maybe_option
    ? finalRecommendation
    : finalRecommendation.filter((item) => item.value !== 'final_recommendation/maybe');

  return (
    <>
      <Subtext>{answer.question_text}</Subtext>
      <FlexContainer>
        {options.map((item) => (
          <OptionContainer
            key={item.value}
            onClick={() => updateFinalRecommendation(item.value)}
            selected={parentObject.final_recommendation === item.value}
          >
            <LabelText>{item.label}</LabelText>
          </OptionContainer>
        ))}
      </FlexContainer>
    </>
  );
};

export default FinalRecommendationAnswer;

const FlexContainer = styled.div`
  display: flex;
`;

export interface OptionsContainerProps {
  selected?: boolean;
}

const OptionContainer = styled.div<OptionsContainerProps>`
  width: 75px;
  height: 48px;

  background: #ffffff;
  border: 1px solid ${(props) => (props.selected ? '#00B88D' : '#D7D7D7')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);
  border-radius: 3px;

  font-weight: bold;
  font-size: 14px;

  text-align: center;

  color: ${(props) => (props.selected ? '#00B88D' : '#999999')};

  mix-blend-mode: normal;

  cursor: pointer;

  &:hover {
    border: 1px solid #00b88d;
    color: #00b88d;
  }
`;

const Subtext = styled.p`
  margin-bottom: 12px;
`;

const LabelText = styled.p`
  line-height: 48px;
  opacity: 0.4;
`;
