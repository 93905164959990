import { styled, Theme, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createButtonUrl } from 'features/MarketplaceV2/features/ExpressInterest/utils';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useRouteMatch } from 'react-router-dom';
import { PrimaryButton, TextButton } from 'sharedComponents/Buttons';
import { nimbleTheme } from 'theme';
import { PROD_URL, STAGING_URL } from 'utils/constants';
import NimbleMobileLogo from '../../assets/nimble-navbar-v2-logo-mobile.png';
import NimbleLogo from '../../assets/nimble-navbar-v2-logo.svg';
import auth from '../../utils/auth';

import { FunctionType } from './types';
import { NavbarV2DataTestIds } from './constants';
export const NavbarV2: React.FC = () => {
  const [userAnchorElement, setUserAnchorElement] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const connectLogin = useRouteMatch({ path: '/connect/login', exact: true });
  const connectSignup = useRouteMatch({ path: '/connect/signup', exact: true });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserAnchorElement(null);
  };

  const handleLogout = () => {
    const url = createButtonUrl({ basePath: '/connect' });
    auth.logout().then(() => window.location.assign(url));
  };

  const handleProfileRedirect = () => {
    const url = createButtonUrl({ basePath: '/connect/profile' });
    history.push(url);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const logoRedirectUrl = () => {
    if (window.location.hostname === PROD_URL) {
      return 'https://app.hirenimble.com/connect';
    } else if (window.location.hostname === STAGING_URL) {
      return 'https://dev.hirenimble.com/connect';
    } else {
      return 'http://localhost:7777/connect';
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const userInfo = auth.getUser();
    if (userInfo !== undefined) {
      setUser(userInfo);
    }
  });

  const settings = [
    { label: 'Profile', menuItemAction: handleProfileRedirect },
    { label: 'Logout', menuItemAction: handleLogout },
  ];

  const contactSupportOnClickHandler = () => {
    window.location.href = 'mailto:connectsupport@hirenimble.com';
  };

  const primaryButtonStyles = {
    fontWeight: nimbleTheme.typography.fontWeightBold,
    fontStyle: 'normal',
    fontSize: isMobile ? '14px' : '16px',
    boxShadow: 'none',
  };

  const secondaryButtonStyles = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: nimbleTheme.typography.fontWeightBold,
    color: nimbleTheme.palette.text.secondary,
  };

  return (
    <NavbarAppBar>
      <NavbarContainer>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            justifySelf: 'center',
          }}
        >
          <Link href={logoRedirectUrl()}>
            {isMobile ? (
              <NimbleMobileLogoContainer src={NimbleMobileLogo} sx={{ mr: 1 }} alt="nimbleLogo" />
            ) : (
              <NimbleLogoContainer src={NimbleLogo} sx={{ mr: 1 }} alt="nimbleLogo" />
            )}
          </Link>
          <Stack direction={'row'} gap={1}>
            {!user && (
              <>
                {!connectSignup && (
                  <RouterLink to="/connect/signup">
                    <PrimaryButton
                      sx={primaryButtonStyles}
                      dataTestId={NavbarV2DataTestIds.CONNECT_SIGN_UP_BUTTON_DATA_TEST_ID}
                    >
                      Create your profile
                    </PrimaryButton>
                  </RouterLink>
                )}
                {!connectLogin && (
                  <RouterLink to="/connect/login">
                    <TextButton sx={secondaryButtonStyles}>Login</TextButton>
                  </RouterLink>
                )}
              </>
            )}
            <TextButton
              onClick={contactSupportOnClickHandler}
              sx={{
                fontSize: isMobile ? '14px' : '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                color: nimbleTheme.palette.text.secondary,
              }}
            >
              {isMobile ? 'Support' : 'Get Support'}
            </TextButton>
            {user && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.first_name} src="placeholder" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={userAnchorElement}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userAnchorElement)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => handleMenuItemClick(setting.menuItemAction)}
                      >
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  ))}
                  {user.is_hijacked && (
                    <MenuItem
                      onClick={() => {
                        auth.handleReleaseHijack();
                      }}
                    >
                      <span>Release Hijack</span>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}
          </Stack>
        </Toolbar>
      </NavbarContainer>
    </NavbarAppBar>
  );
};

const NavbarAppBar = styled(AppBar)(({ theme }) => ({
  position: 'static',
  background: 'white',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  borderBottom: '1px solid #EAECF0',
  padding: '0px 32px',

  [theme.breakpoints.down('lg')]: {
    padding: '0px 16px',
  },
}));

const NavbarContainer = styled(Box)(() => ({
  justifyContent: 'space-between',
  width: '100%',
}));

const NimbleLogoContainer = styled('img')(() => ({
  display: 'flex',
  width: '132.656px',
  height: '36.129px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
}));

const NimbleMobileLogoContainer = styled('img')(() => ({
  display: 'flex',
  width: '36.129px',
  height: '36.129px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
}));

const handleMenuItemClick = (menuItemAction: string | FunctionType) => {
  if (typeof menuItemAction === 'function') {
    menuItemAction();
  } else if (typeof window[menuItemAction] === 'function') {
    window[menuItemAction]();
  }
};
