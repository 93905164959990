import { Component } from 'react';
import styled from 'styled-components';
import {
  USStates,
  credentialNewJerseyTeachingCertificate,
  examTypesMissouri,
} from '../../../../utils/enums';
import moment from 'moment';
import { BulletPoint } from 'ui-kit/icons';
import { Credential } from 'types/types';
import { BoxContainer } from '../../../CandidatesList/QuickProfileCandidate/QuickView.styles';

interface Props {
  subjects: Credential[];
  hasNoCredentials: boolean;
  isTalentMarketplace?: boolean;
  isCandidateList?: boolean;
}

class Certification extends Component<Props> {
  //TODO: make (+) pill clickable and functional

  getCredential = (credential: Credential): string => {
    let credentialString = '';

    const { label: state } = USStates().find((s) => s.value === credential.state) || {};
    if (state) {
      credentialString += state + ' -- ';
    }
    if (!state) {
      credentialString += 'Other Credential -- ';
    }

    const { label: nj_certificate } =
      credentialNewJerseyTeachingCertificate().find((s) => s.value === credential.nj_certificate) ||
      {};
    if (nj_certificate) {
      credentialString += 'Do you hold a NJ teaching certificate? ' + nj_certificate + ' -- ';
    }

    const status = credential?.status?.value;
    if (status) {
      credentialString += 'Status/Type: ' + status + ' -- ';
    }

    const subject = credential?.subject?.value;
    if (subject) {
      credentialString += 'Category: ' + subject + ' -- ';
    }

    const grade = credential?.grade?.value;
    if (grade) {
      credentialString += 'Grade Level: ' + grade + ' -- ';
    }

    const license_number = credential.license_number;
    if (license_number) {
      credentialString += `License: ${license_number} -- `;
    }

    const issueDate = credential.issue_date;
    if (issueDate) {
      credentialString += `Issue date: ${moment(issueDate).format('MM/D/YYYY')} -- `;
    }

    const expirationDate = credential.expiration_date;
    if (expirationDate) {
      credentialString += `Expiration date: ${moment(expirationDate).format('MM/D/YYYY')}`;
    }

    const credentialExplanation = credential.credential_explanation;
    if (credentialExplanation) {
      credentialString += `Candidate Explanation: ${credentialExplanation}`;
    }

    const credentialExams = credential.credential_exams_passed;
    if (credentialExams) {
      const examTypesMissouriArray = examTypesMissouri();
      let examsMissouriString = `Exams Passed: ${credentialExams.map((item) => {
        const string = examTypesMissouriArray.find((a) => a.value === item).label;
        return string;
      })}`;
      examsMissouriString = examsMissouriString.replace(/,/g, '; ');

      credentialString += examsMissouriString;
    }

    // Removing trailing dashes if necessary
    if (credentialString.substr(credentialString.length - 3) === '-- ') {
      credentialString = credentialString.substr(0, credentialString.length - 3);
    }

    return credentialString;
  };

  getTalentCredential = (credential: Credential): string => {
    // This function generates the credential string show in the QuickView
    let credentialString = '';

    const { label: state } = USStates().find((s) => s.value === credential.state) || {};
    if (state) {
      credentialString += state + ' -- ';
    }
    if (!state) {
      credentialString += 'Other Credential -- ';
    }

    const subject = credential?.subject?.value;
    if (subject) {
      credentialString += 'Category: ' + subject + ' -- ';
    }

    const grade = credential?.grade?.value;
    if (grade) {
      credentialString += 'Grade Level: ' + grade + ' -- ';
    }

    if (credentialString.substr(credentialString.length - 3) === '-- ') {
      credentialString = credentialString.substr(0, credentialString.length - 3);
    }

    return credentialString;
  };

  render(): JSX.Element {
    const { subjects, hasNoCredentials, isCandidateList, isTalentMarketplace }: Props = this.props;
    if (!subjects && !hasNoCredentials) {
      return <div />;
    }

    return (
      <>
        {isCandidateList && (
          <BoxContainer>
            <HeaderLabel>Credentials</HeaderLabel>
            <LineItemGridContainer>
              {/* Filter out empty entries */}
              {subjects
                .filter(
                  (subject) =>
                    subject?.grade ||
                    subject?.license_number !== '' ||
                    subject?.state ||
                    subject?.status ||
                    subject?.subject
                )
                .map((subject) => (
                  <LineItem key={subject.id}>
                    <BulletPoint />
                    <p>{this.getCredential(subject)}</p>
                  </LineItem>
                ))}
              {hasNoCredentials && (
                <LineItem>
                  <BulletPoint />
                  <p>None</p>
                </LineItem>
              )}
            </LineItemGridContainer>
          </BoxContainer>
        )}
        {isTalentMarketplace && (
          <MarketplaceCredentialContainer>
            <MarketplaceHeaderLabel>Credentials</MarketplaceHeaderLabel>
            <MarketplaceLineItemContainer>
              {/* Filter out empty entries */}
              {subjects
                .filter(
                  (subject) =>
                    subject?.grade ||
                    subject?.license_number !== '' ||
                    subject?.state ||
                    subject?.status ||
                    subject?.subject
                )
                .map((subject) => (
                  <MarketplaceLineItem key={subject.id}>
                    <p>{this.getTalentCredential(subject)}</p>
                  </MarketplaceLineItem>
                ))}
              {hasNoCredentials && (
                <MarketplaceLineItem>
                  <p>None</p>
                </MarketplaceLineItem>
              )}
            </MarketplaceLineItemContainer>
          </MarketplaceCredentialContainer>
        )}
      </>
    );
  }
}

export default Certification;

const MarketplaceCredentialContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-top: 16px;
  padding-left: 19px;
`;

const MarketplaceLineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarketplaceLineItem = styled.div`
  color: #828282;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 16px;
  align-items: center;
  font-family: 'Open Sans';
  order: 1;
  flex-grow: 0;
`;

const MarketplaceHeaderLabel = styled.div`
  color: #4F4F4F;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  line-height: 24px;
  display; flex;
  align-items: center;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`;

const LineItemGridContainer = styled.div`
  margin-top: 14px;
  display: grid;
  grid-gap: 10px;
`;

const LineItem = styled.div`
  color: #777777 !important;
  font-weight: 400;
  min-height: 22px;

  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
`;

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;
