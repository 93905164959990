import { Component, ReactNode } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import auth from '../../../utils/auth.js';
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  OnAddHandlerFunc,
  OnChangeHandlerFunc,
} from 'react-mentions';
import mentionStyles from './mentionStyles.js';
import { User } from 'types/types';
import { InternalCandidateNotesProps, InternalCandidateNotesState } from './types';
import { NewNote } from 'components/CandidatesList/CandidateRow/types.js';
import { AdminUser } from 'components/CandidatesList/MarketplaceProspectQuickView/types.js';
import { ExistingNotes } from './ExistingNotes';

export default class InternalCandidateNotes extends Component<
  InternalCandidateNotesProps,
  InternalCandidateNotesState
> {
  user: User;

  constructor(props: InternalCandidateNotesProps) {
    super(props);
    this.user = auth.getUser();
    this.state = {
      text: '',
      seeMore: false,
      loading: false,
      taggedUsers: [],
    };
    this.addNote = this.addNote.bind(this);
  }

  handleChange: OnChangeHandlerFunc = (event) => {
    const text = event.target.value;
    this.setState({ text });
  };

  async addNote(): Promise<void> {
    const { taggedUsers, text } = this.state;
    const regExp = /@\[(.*?)\]/g;
    const finalUserList = text.match(regExp) || [];

    if (taggedUsers.length !== finalUserList.length) {
      // if these lengths are different, a tag was removed. Search
      // for the user(s) not present in finalUserList, and remove.
      let i = taggedUsers.length;
      while (i--) {
        const fullName = `${taggedUsers[i].first_name} ${taggedUsers[i].last_name}`;
        // substring below changes '@[First Name]' to 'First name', for comparison with fullName
        if (!finalUserList.find((item) => item.substring(2, item.length - 1) === fullName)) {
          taggedUsers.splice(i, 1);
        }
      }
    }

    const created_by = `${this.user.first_name} ${this.user.last_name}`;
    const created_at = new Date(moment.now());
    const newNote: NewNote = { text, created_by, created_at };

    this.props.updateNote(newNote, taggedUsers, text);
    this.clearStaging();
  }

  clearStaging = (): void => {
    const text = '';
    const taggedUsers: AdminUser[] = [];
    this.setState({
      text,
      taggedUsers,
    });
  };

  renderSeeNotesButton = (): ReactNode => {
    const notes = this.props.notes;
    let notesCharacterCount = 0;
    for (let i = 0; i < notes.length; i++) {
      notesCharacterCount += notes[i].text.length;
    }
    if (notes.length <= 2 && notesCharacterCount <= 200) {
      return null;
    }
    return (
      <Expand
        onClick={() =>
          this.setState((prevState) => {
            return { seeMore: !prevState.seeMore };
          })
        }
      >
        <span className="pointer">{this.state.seeMore ? 'See less' : 'See more'}</span>
      </Expand>
    );
  };

  renderUserSuggestion = (
    _suggestion: SuggestionDataItem,
    _search: string,
    highlightedDisplay: ReactNode
  ): JSX.Element => {
    return <div>{highlightedDisplay}</div>;
  };

  onAdd: OnAddHandlerFunc = (id) => {
    /** callback function that runs when a user is tagged. Add tagged user to state, and email that list
     *  when the note is published (which happens in the addNote function).
     */
    const taggedUsers = this.state.taggedUsers;
    const user = this.props.adminUsers.find((admin) => admin.id === Number(id));
    // don't tag same user multiple times, because that will send multiple emails
    if (taggedUsers.includes(user)) {
      return;
    }
    taggedUsers.push(user);
    this.setState({ taggedUsers });
  };

  render(): ReactNode {
    const mappedData = this.props.adminUsers.map((adminUser) => ({
      ...adminUser,
      id: String(adminUser.id),
    }));

    return (
      <div>
        <ExistingNotes extended={this.state.seeMore} notes={this.props.notes} />
        {this.renderSeeNotesButton()}
        <MentionsInput
          value={this.state.text}
          onChange={this.handleChange}
          placeholder={"Mention people using '@'"}
          style={mentionStyles}
          markup="@[__display__]"
          displayTransform={(_id: string, display: string) => `@${display}`}
        >
          <Mention
            trigger="@"
            style={{ backgroundColor: 'var(--gray)' }}
            data={mappedData}
            renderSuggestion={this.renderUserSuggestion}
            onAdd={this.onAdd}
          />
        </MentionsInput>
        <AddNoteButton onClick={this.addNote}>Add Note</AddNoteButton>
        {/* div style=clear keeps the floated button in the document flow */}
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

const AddNoteButton = styled.button`
  color: white;
  background-color: #00b88d;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 155px;
  height: 35px;
  float: right;
`;

const Expand = styled.div`
  text-align: center;
  color: var(--green);
  margin: -5px auto 10px;
  font-size: 13px;
`;
