import { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import { PrimaryButton } from 'sharedComponents/Buttons/PrimaryButton';
import { SplitButtonProps } from './types';

const options = ['Create & Save', 'Create & Edit'];

export const SplitButton: React.FC<SplitButtonProps> = ({
  handleSubmitButtonClick,
  hasError,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleItemClick = (e) => handleSubmitButtonClick(e.target.textContent);

  return (
    <>
      <ButtonGroup
        aria-label="split button"
        disabled={isLoading || hasError}
        ref={anchorRef}
        variant="contained"
      >
        <PrimaryButton onClick={handleItemClick} size="large">
          {options[selectedIndex]}
        </PrimaryButton>
        <PrimaryButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label="select merge strategy"
          onClick={handleToggle}
          size="small"
          sx={{ padding: 0 }}
        >
          <ArrowDropDownIcon />
        </PrimaryButton>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        placement="bottom-end"
        sx={{
          zIndex: 1,
        }}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      onClick={() => {
                        handleMenuItemClick(index);
                      }}
                      selected={index === selectedIndex}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
