import { useState } from 'react';

import { Alert } from 'sharedComponents/Alert';
import { appStatusType } from 'utils/enums';
import { ATSJobClosureNotificationTestIds } from 'data-testids/ATS';
import { Box, useTheme, Divider, Typography } from '@mui/material';
import { DeletionModal } from 'sharedComponents/Modal/DeletionModal';
import { EditDeleteButtonContainer, NewHeading, StyledLinkButton } from './styles';
import { JobClosureModal } from './JobClosureModal';
import { JobClosureNotificationsProps, LocalJobClosureNotification } from './types';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { SuccessSnackbar } from 'sharedComponents/Snackbar';
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useEditNotificationMutation,
} from './mutations';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';

export const JobClosureNotifications: React.FC<JobClosureNotificationsProps> = ({
  applicationStatuses,
  data,
  districtAndSuperAdmins,
  isLoading,
  jobId,
}) => {
  const hiredStatusId = applicationStatuses?.find(
    (status) => status.status_type === appStatusType?.hired
  )?.id;

  const defaultFormState = {
    applicationStatusId: data?.alerts[0]?.context.application_status_id || hiredStatusId,
    subscribers: data?.subscribers || null,
    threshold: data?.alerts[0]?.context.threshold || 1,
  };

  const theme = useTheme();
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [successToastMessaging, setSuccessToastMessaging] = useState(null);
  const [pendingNotification, setPendingNotification] =
    useState<LocalJobClosureNotification>(defaultFormState);
  const [shouldShowDeletionConfirmationModal, setShouldShowDeletionConfirmationModal] =
    useState(false);
  const [shouldShowDeleteToast, setShouldShowDeleteToast] = useState(false);

  const handleOpenModal = () => setIsModalActive(true);
  const handleCloseModal = () => {
    setIsSubmitting(false);
    setPendingNotification(defaultFormState);
    setIsModalActive(false);
  };

  const handleThresholdChange = (event: { target: { value: string } }) => {
    return setPendingNotification((previousState) => ({
      ...previousState,
      threshold: Number(event.target.value),
    }));
  };

  const handleStatusChange = (id) => {
    return setPendingNotification((previousState) => ({
      ...previousState,
      applicationStatusId: id,
    }));
  };

  const handleSubscribersChange = (newValues) => {
    const subscriberIds = newValues.map((newValue) => newValue.id);

    return setPendingNotification((previousState) => ({
      ...previousState,
      subscribers: subscriberIds,
    }));
  };

  const addNotificationQuery = useCreateNotificationMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setSuccessToastMessaging('Successfully added job closure notification');
      return setIsModalActive(false);
    },
  });

  const editNotificationQuery = useEditNotificationMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setSuccessToastMessaging('Successfully edited job closure notification');
      return setIsModalActive(false);
    },
  });

  const deleteNotificationQuery = useDeleteNotificationMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setPendingNotification({
        applicationStatusId: hiredStatusId,
        subscribers: null,
        threshold: 1,
      });

      setShouldShowDeleteToast(true);
      return setIsModalActive(false);
    },
  });

  const handleAdd = () => {
    setIsSubmitting(true);
    const { subscribers, threshold, applicationStatusId } = pendingNotification;

    const isValidSubscribers = subscribers && subscribers.length > 0;
    const isValidThreshold = threshold && threshold > 0 && Number.isInteger(threshold);

    if (isValidSubscribers && isValidThreshold && applicationStatusId) {
      return addNotificationQuery.mutate({ jobId, pendingNotification: pendingNotification });
    }

    return;
  };

  const handleEdit = () => {
    setIsSubmitting(true);
    const { subscribers, threshold, applicationStatusId } = pendingNotification;
    const { role_notification_id } = data;

    const isValidSubscribers = subscribers && subscribers.length > 0;
    const isValidThreshold = threshold && threshold > 0 && Number.isInteger(threshold);

    if (isValidSubscribers && isValidThreshold && applicationStatusId) {
      return editNotificationQuery.mutate({
        notificationId: role_notification_id,
        jobId,
        pendingNotification,
      });
    }

    return;
  };

  const handleDelete = () => {
    const notificationId = data.role_notification_id;
    deleteNotificationQuery.mutate({ jobId, notificationId });
    return setShouldShowDeletionConfirmationModal(false);
  };

  const isMutationPending =
    addNotificationQuery.isLoading ||
    editNotificationQuery.isLoading ||
    deleteNotificationQuery.isLoading;

  const isLoadingDataOrMutationPending = isLoading || isMutationPending;

  const getAlertText = () => {
    const alertStatus = data.alerts[0]?.status;

    if (alertStatus === 'triggered') {
      return (
        <>
          Your job closure notification was sent on &nbsp;
          <b>{moment(data.alerts[0].triggered_on).format('LL')}</b>.
        </>
      );
    }

    const alertThreshold = data.alerts[0]?.context.threshold;
    const canddiateAndAction =
      alertThreshold === 1 ? 'candidate reaches or passes' : 'candidates reach or pass';

    const statusToDisplay = applicationStatuses.find(
      (status) => status.id === data.alerts[0]?.context.application_status_id
    )?.label;

    return (
      <>
        A job closure notification will be sent when <b>{alertThreshold}</b> {canddiateAndAction}{' '}
        the status of <b>{statusToDisplay}</b>
      </>
    );
  };

  const filteredApplicationStatuses = applicationStatuses?.filter(
    (status) => status.status_type !== appStatusType.archived
  );

  return (
    <>
      <NewHeading data-testid={ATSJobClosureNotificationTestIds.HEADING}>
        <Typography variant="heading7" style={{ lineHeight: theme.spacing(5) }}>
          Job Closure Notification
        </Typography>
        {data && (
          <EditDeleteButtonContainer>
            <StyledLinkButton
              data-testid={ATSJobClosureNotificationTestIds.EDIT_NOTIFICATION_BUTTON}
              onClick={handleOpenModal}
            >
              Edit
            </StyledLinkButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <StyledLinkButton
              data-testid={ATSJobClosureNotificationTestIds.DELETE_NOTIFICATION_BUTTON}
              onClick={() => setShouldShowDeletionConfirmationModal(true)}
              sx={{
                '&:hover': {
                  color: theme.palette.error.dark,
                },
              }}
            >
              Delete
            </StyledLinkButton>
          </EditDeleteButtonContainer>
        )}
      </NewHeading>
      <Box sx={{ top: theme.spacing(0.5) }}>
        {!data ? (
          <SecondaryButton
            sx={{ fontWeight: theme.typography.fontWeightMediumBold }}
            dataTestId={ATSJobClosureNotificationTestIds.ADD_NOTIFICATION_BUTTON}
            size="extraLarge"
            onClick={handleOpenModal}
          >
            + Add Notification
          </SecondaryButton>
        ) : (
          <Alert icon={false} dataTestId={ATSJobClosureNotificationTestIds.ALERT_BANNER}>
            {getAlertText()}
          </Alert>
        )}
      </Box>
      {isModalActive && (
        <JobClosureModal
          applicationStatuses={filteredApplicationStatuses}
          applicationStatusId={pendingNotification.applicationStatusId}
          districtAndSuperAdmins={districtAndSuperAdmins}
          handleAdd={handleAdd}
          handleCloseModal={handleCloseModal}
          handleEdit={handleEdit}
          handleStatusChange={handleStatusChange}
          handleSubscribersChange={handleSubscribersChange}
          handleThresholdChange={handleThresholdChange}
          hasExistingNotifications={Boolean(data)}
          isLoadingDataOrMutationPending={isLoadingDataOrMutationPending}
          isModalActive={isModalActive}
          isSubmitting={isSubmitting}
          subscriberIds={pendingNotification.subscribers}
          thresholdValue={pendingNotification.threshold}
        />
      )}
      {successToastMessaging && (
        <SuccessSnackbar
          message={successToastMessaging}
          onClose={() => setSuccessToastMessaging(null)}
          open={Boolean(successToastMessaging)}
          sx={{ top: 0 }}
        />
      )}
      {shouldShowDeletionConfirmationModal && (
        <DeletionModal
          onClose={() => setShouldShowDeletionConfirmationModal(false)}
          onDelete={handleDelete}
          open={shouldShowDeletionConfirmationModal}
          text="You will no longer be notified when this job is ready to be closed."
          title="Are you sure?"
        />
      )}

      {shouldShowDeleteToast && (
        <SuccessSnackbar
          alertTitle="Delete Successful"
          icon={<DeleteOutlineOutlinedIcon fontSize="medium" />}
          message="The job closure notification has been deleted"
          onClose={() => setShouldShowDeleteToast(false)}
          open={shouldShowDeleteToast}
          severity="error"
          sx={{ fontSize: 'inherit' }}
          variant="standard"
        />
      )}
    </>
  );
};
